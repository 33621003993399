<template>
  <div class="audio-wrapper" @click="toggle">
    <div class="sound" :class="{ 'sound-mute': !playing }">
      <div class="sound--icon material-icons">volume_mute</div>
      <div class="sound--wave sound--wave_one"></div>
      <div class="sound--wave sound--wave_two"></div>
    </div>
    <audio ref="audio" :src="file" :loop="loop" style="display:none"></audio>
  </div>
</template>

<script>
import { setTimeout } from "timers";
export default {
  props: {
    file: {
      type: String
    },
    autoplay: {
      type: Boolean,
      default() {
        return false;
      }
    },
    loop: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      audio: undefined,
      playing: false,
      ready: false
    };
  },
  methods: {
    play() {
      this.playing = true;
      this.audio.play();
    },
    pause() {
      this.playing = false;
      this.audio.pause();
    },
    toggle() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    }
  },
  mounted() {
    this.audio = this.$refs.audio;
    this.ready = this.$root.ready;
  },
  computed: {
    rootReady() {
      return this.$root.ready;
    }
  },
  watch: {
    rootReady() {},
    ready() {
      if (this.autoplay && this.ready && this.audio) {
        this.play();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.audio-wrapper {
  position: fixed;
  z-index: 6;
  left: 5px;
  bottom: 5px;
  // background: $teal-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  outline: 0;

  $color: #f0bf7c;
  // $color: linear-gradient(
  //     to bottom,
  //     #be965d,
  //     #eebc79
  //   ) 1 100%;
  $size: 60;
  $border: $size / 25 * 1px;

  .sound {
    width: $size * 1px;
    height: $size * 1px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    user-select: none;

    &--icon {
      color: $color;
      width: 75%;
      height: 100%;
      line-height: 100%;
      font-size: $size / 1.5 * 1px;
      display: flex;
      align-items: center;
    }

    &--wave {
      position: absolute;
      border: $border solid transparent;
      border-right: $border solid;
      border-right-color: $color;
      border-radius: 50%;
      transition: all 200ms;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &_one {
        width: $size * 0.5%;
        height: $size * 0.5%;
      }

      &_two {
        width: $size * 1%;
        height: $size * 1%;
      }
    }

    &-mute .sound--wave {
      border-radius: 0;
      width: 30%;
      height: 30%;
      border-width: 0 $border 0 0;

      &_one {
        transform: rotate(45deg) translate3d(0, -45%, 0);
      }

      &_two {
        transform: rotate(-45deg) translate3d(0, 45%, 0);
      }
    }
  }

  button {
    background: transparent;
    border: none;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $teal-dark;
    outline: 0;

    i {
      font-size: 40px;
      line-height: 1;
      position: relative;
      display: block;
      transform: all 0.4s ease-in;

      &:before {
        width: 100%;
        transform: rotate(45deg);
        height: 6px;
        border-top: 3px solid #fff;
        background: $teal-dark;
        content: " ";
        position: absolute;
        top: 50%;
        margin-top: -2px;
        left: 2px;
      }
    }

    &.playing {
      i {
        &:before {
          width: 0%;
        }
      }
    }
  }
}
</style>
