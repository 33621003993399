<template>
  <section class="mepandes" v-if="mepandes.length">
    <div class="container">
      <div
        class="heading text-center with-border mar-bottom-40"
        data-aos="fade-up"
      >
        <h2 class="title text-uppercase text-teal-dark">Mepandes</h2>
        <div class="sub-title">Potong Gigi</div>
      </div>

      <ul class="text-center persons row justify-content-center">
        <li
          class="person col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-md-5 px-2"
          data-aos="fade-up"
          v-for="person in mepandes"
          :key="person"
        >
          <!-- <img src="@/assets/icons/arrow.svg" /> -->
          <div
            class="py-4 px-3"
            style="background: rgba(119, 119, 119, 0.05); justify-self: stretch; align-self: stretch; height: 100%; display: flex; flex-direction: column;"
          >
            <!-- <div>
              <img
                :src="person.image"
                class=""
                style="opacity: 1; border-radius: .5rem; width: 50%; max-width: 200px"
              />
            </div> -->
            <div class="font-secondary" style="font-size: 1.75rem">
              {{ person.name }}
            </div>
            <div class="text-muted" style="font-size: 70%; margin-top: auto">
              {{ person.description }}
            </div>
            <div class="text-muted" style="font-size: 70%">
              {{ person.parents }}
            </div>
          </div>
          <!-- <img src="@/assets/icons/arrow.svg" class="flip-horinzontal" /> -->
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: ["mepandes"]
};
</script>
